<template>
    <!-- 我的收藏 -->
    <div class="customer_box">
        <!-- <div class="customer_search_box">
      <div class="search-item">
        <span>关键字</span>
        <el-input style="width: 190px;" clearable placeholder="商品名称" size="small" v-model="filterinfo.search"></el-input>
      </div>
      <div class="btn_box">
        <el-button class="btn_item" type="primary" size="small" @click="getCustomerList(1)">查询</el-button>
        <el-button class="btn_item" size="small" @click="resetClick">重置</el-button>
      </div>
    </div> -->
        <div class="goods_box" v-loading="loading">
            <div class="box_list" v-if="goodsList.length">
                <div class="goods_item" v-for="(item,index) in goodsList" :key="item.goodsId" @click="$router.push('/goodsDetail?goodsId='+item.goodsId)">
                    <!-- 商品图片区 -->
                    <div class="goods_img">
                        <img :src="item.thumbUrl" alt="">
                        <img v-if="item.stock == 0" class="nostock" src="@/assets/images/index/nostock.png" alt="">
                        <div class="tag">
                            <span :style="item1.actType == 2||item1.actType == 4?'':'display: none'" :class="{tj:item1.actType == 2,yh:item1.actType == 4}" v-for="(item1,index1) in item.acts" :key="index1">{{item1.name}}</span>
                        </div>
                    </div>
                    <!-- 商品信息区 -->
                    <div class="goods_info">
                        <div class="title">{{item.productName}}</div>
                        <div class="spesc">
                            <img src="@/assets/images/index/goods_gg.svg" alt="">
                            <el-tooltip class="item" effect="dark" :disabled="item.norms&&item.norms.length<25" :content="item.norms" placement="top-start">
                                <span>{{item.norms}}</span>
                            </el-tooltip>
                        </div>
                        <div class="spesc">
                            <img src="@/assets/images/index/goods_cj.svg" alt="">
                            <span>{{item.produceUnit?item.produceUnit:'--'}}</span>
                        </div>
                        <div class="spesc">
                            <img src="@/assets/images/index/goods_xq.svg" alt="">
                            <span>优于{{item.lastDate?item.lastDate:'--'}}</span>
                        </div>
                        <div class="price" v-html="initPrice(item.jiage)"></div>
                        <div class="gross">
                            <span>毛利率 {{item.grossMargin?item.grossMargin:'--'}}</span>
                            <span>零售价 ￥{{(item.retailPrice).toFixed(2)}}</span>
                        </div>
                    </div>
                    <!-- 商品加购收藏区 -->
                    <div class="goods_purchase" @click.stop="">
                        <div class="purchase_top" :style="$route.query.prepareOrderNumber?'justify-content: space-between;':'justify-content: flex-start;'">
                            <div v-if="!$route.query.prepareOrderNumber" class="collect" @click="favoriteClick(item.goodsId,goodsList,index)">
                                <img v-if="item.favorite" src="@/assets/images/index/collect_a.svg" alt="">
                                <img v-else src="@/assets/images/index/collect.svg" alt="">
                            </div>
                            <el-input-number class="number" v-model="item.buyNumber" @change="addNumberChange(item)" :disabled="item.stock == 0" size="small" :min="item | minStep" :step="item | minStep" :precision="0"></el-input-number>
                            <div v-if="item.cartNumber>0&&!$route.query.prepareOrderNumber" class="add exist" :class="{disabled:item.stock == 0}" @click="addPurchase(item)">
                                <span>已在采购车</span>
                            </div>
                            <div v-else class="add" :class="{disabled:item.stock == 0}" @click="addPurchase(item)">
                                <span>{{$route.query.prepareOrderNumber?'加入订单':'加入采购车'}}</span>
                            </div>
                        </div>
                        <span>库存{{item.stock}}</span>
                    </div>
                </div>
            </div>
            <div class="nodata_box" v-else>
                <img src="@/assets/images/index/nodata.png" alt="">
                <span>暂无数据哦~</span>
            </div>
        </div>
        <div class="pagination_box" v-if="goodsList.length">
            <el-pagination background layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 30, 40]" :page-size.sync="filterinfo.pageSize" :current-page.sync="filterinfo.page" :total="total" @current-change="getCustomerList" @size-change="getCustomerList(1)">
            </el-pagination>
        </div>
        <cartDialog v-if="cartList.length" @realAddCar="realAddCar" @closeDialog="closeDialog" :countStep="goodsItem| minStep" :cartList="cartList" :farenVisible="farenVisible"></cartDialog>
        <priceDialog v-if="tipVisible" @tipClose="tipClose" @closePriceDialog="noBuy" :priceList="tipList" :tipVisible="tipVisible"></priceDialog>
    </div>
</template>

<script>
import { getCarNum, getOrderNum } from '@/utils/carNumber'
import cartDialog from '@/views/goodsDetail/component/cartDialog.vue'
import priceDialog from '@/views/shopCar/component/priceDialog.vue'
export default {
    name: 'MyFavorites',
    components: {
        cartDialog,
        priceDialog
    },
    data() {
        return {
            loading: false,
            farenVisible: false,
            tipVisible: false,
            total: 0,
            filterinfo: {
                page: 1,
                pageSize: 20,
            },
            goodsList: [],//搜索商品列表
            cartList: [],//
            tipList: [],//
            goodsItem: '',
        };
    },
    filters: {
        minStep(item) {
            let num = 1
            item.Pack.forEach(item1 => {
                if (item1.isPack == 'on') {
                    num = item1.num
                }
            })
            return Number(num)
        }
    },
    created() {

    },
    mounted() {
        this.getCustomerList()
    },
    beforeDestroy() {
        // 取消事件监听器
        console.log(122212);

        this.tipVisible = false
    },
    methods: {
        // 点击重置
        resetClick() {
            this.filterinfo = {
                page: 1,
                pageSize: 20,
            }
            this.getCustomerList()
        },
        tipClose() {
            this.tipVisible = false
        },
        noBuy() {
            this.tipVisible = false
        },
        closeDialog() {
            this.farenVisible = false
        },
        async realAddCar(cartList) {
            const arr = cartList.filter((item) => item.checked && item.quantity)
            if (!arr.length) return this.$message('请选择要购买的门店')

            const cartMultipleMemberNum = arr.map(item => {
                return {
                    memberId: item.memberId,
                    num: item.quantity,
                }
            })
            console.log(cartMultipleMemberNum);
            const res = await this.$api.multipleAddCart({
                goodsId: this.goodsId,
                cartMultipleMemberNum
            })
            console.log(res);
            if (res.data.code == 200) {
                const faliList = res.data.data.filter(item => item.cartNoticeResp[0].state != 200)
                if (!faliList.length) {
                    this.$message({
                        message: '加入采购车成功',
                        type: 'success'
                    })
                } else {
                    if (cartMultipleMemberNum.length == faliList.length) {
                        this.$notify({
                            title: '提示',
                            message: faliList.map(item => item.companyName + item.cartNoticeResp[0].message).join('\n'),
                            type: 'warning'
                        });
                    } else {
                        this.$notify({
                            title: '提示',
                            message: faliList.map(item => item.companyName + item.cartNoticeResp[0].message).join('\n') + '，其余客户加入购物车成功',
                            type: 'warning'
                        });
                    }
                }
            }
            getCarNum(this)
            this.farenVisible = false

        },
        // 获取列表
        getCustomerList(num) {
            if (num == 1) {
                this.filterinfo.page = 1
            }
            let params = this.filterinfo
            this.loading = true
            this.$api.myCollection(params).then((res) => {
                this.loading = false
                if (res.data.code === 200) {
                    this.goodsList = res.data.data.list ? res.data.data.list : []
                    this.total = res.data.data.total
                }
            });
        },
        // 收藏点击
        favoriteClick(id, list, index) {
            let params = {
                goodsId: id
            }
            this.$api.pcProductDoFav(params).then((res) => {
                if (res.data.code === 200) {
                    list[index].favorite = list[index].favorite == true ? false : true
                    list = list.filter(item => item)
                    let msg = list[index].favorite ? '收藏成功' : '取消收藏成功'
                    this.$toast(msg);
                    this.getCustomerList()
                }
            });
        },
        // 加购输入框限制
        addNumberChange(item) {
            var number = 1
            item.Pack.forEach(item1 => {
                if (item1.isPack == 'on') {
                    number = item1.num
                }
            })
            if (item.buyNumber % number != 0) {
                this.$toast('加购数量必须为中包装数量倍数！');
                item.buyNumber = number
            }
        },
        // 加入采购车
        async addPurchase(item) {
            console.log(item);

            this.goodsId = item.goodsId
            this.goodsItem = item
            if (this.$store.state.memberInfo.isMain == 1) {
                const res = await this.$api.storeList({ goodsId: item.goodsId })
                console.log(res);
                if (res.data.code == 200) {

                    res.data.data.multipleMemberInfo.forEach(item => {
                        item.checked = false
                        item.quantity = 0
                    })
                    this.cartList = res.data.data.multipleMemberInfo
                    console.log(this.cartList);

                    this.farenVisible = true

                }

            } else {
              if (item.stock == 0) {
                    this.$toast('该商品即将到货！');
                } else {
                    let params = {
                        goodsId: item.goodsId,
                        num: item.buyNumber,
                        actType: 1,
                        actId: 0,
                    }
                    if (this.$route.query.prepareOrderNumber) params.prepareOrderNumber = this.$route.query.prepareOrderNumber
                    this.$api.pcJoinCar(params).then((res) => {
                        if (res.data.code === 200) {

                            if (res.data.data.state === 200) {
                                this.$toast(res.data.data.message);
                                // 成功加入采购车后按钮状态变成已在采购车
                                item.cartNumber = item.buyNumber
                            } else if (res.data.data.state == 300102) {
                                this.tipList = [{
                                  goodsId:item.goodsId,
                                    productName: item.productName
                                }]
                                this.tipVisible = true
                            } else {
                                this.$message({
                                    message: res.data.data.message,
                                    type: "warning"
                                })
                            }
                            // 更新采购车数量
                            getCarNum(this)
                        }
                    });
                }
            }

        },
    }
};
</script>
<style lang="scss" scoped>
.customer_box {
    position: relative;
    padding-top: 8px;

    .goods_box {
        .box_list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .goods_item {
                position: relative;
                width: 242px;
                height: 398px;
                box-sizing: border-box;
                margin: 0 16px 16px 0;
                background: #ffffff;
                border-radius: 2px 2px 2px 2px;
                cursor: pointer;

                &:hover {
                    .goods_purchase {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                &:nth-of-type(4n) {
                    margin-right: 0;
                }

                .goods_img {
                    position: relative;
                    text-align: center;
                    margin-bottom: 4px;

                    .nostock {
                        width: 132px;
                        height: 132px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        margin: auto;
                    }

                    img {
                        width: 242px;
                        height: 242px;
                        vertical-align: middle;
                    }

                    .tag {
                        position: absolute;
                        left: 10px;
                        bottom: 0;
                        display: flex;
                        align-items: center;

                        span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 33px;
                            height: 21px;
                            box-sizing: border-box;
                            border-radius: 3px 3px 3px 3px;
                            font-size: 12px;
                            line-height: 0px;
                        }

                        .tj {
                            background: rgba(248, 213, 213, 0.9);
                            border: 1px solid #ffacac;
                            color: #f92b2b;
                            margin-right: 4px;
                        }
                        .zy {
                            background: rgba(206, 231, 249, 0.9);
                            border: 1px solid #b7dcf8;
                            color: #1a9dff;
                            margin-right: 4px;
                        }
                        .yh {
                            width: auto;
                            padding: 0 6px;
                            background: rgba(248, 240, 223, 0.9);
                            border: 1px solid #fad894;
                            color: #f7a400;
                        }
                    }
                }

                .goods_info {
                    padding: 0 10px;

                    .title {
                        font-weight: bold;
                        font-size: 16px;
                        color: #333333;
                        margin-bottom: 6px;
                        white-space: nowrap; /* 不换行 */
                        overflow: hidden; /* 超出部分隐藏 */
                        text-overflow: ellipsis; /* 溢出部分显示省略号 */
                    }
                    .spesc {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        color: #666666;
                        margin-bottom: 5px;

                        span {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        img {
                            margin-right: 3px;
                        }
                    }
                    .invent {
                        color: #666666;
                    }
                    .price {
                        color: #f34141;
                        font-weight: bold;
                        font-size: 16px;
                        margin-bottom: 3px;
                    }
                    .gross {
                        display: inline-block;
                        width: 100%;
                        line-height: 0px;
                        padding: 6px 17px 6px 7px;
                        background: #ecf6ff;
                        border-radius: 2px 2px 2px 2px;
                        font-size: 12px;
                        color: #4e6474;
                        box-sizing: border-box;

                        span {
                            &:first-of-type::after {
                                content: '';
                                display: inline-block;
                                width: 1px;
                                height: 10px;
                                background: #c4d4e2;
                                margin: 0 6px 0 9px;
                            }
                        }
                    }
                }

                .goods_purchase {
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.5s;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 8px 10px;
                    background: rgba(0, 0, 0, 0.3);
                    font-size: 12px;
                    color: #ffffff;
                    cursor: auto;

                    .purchase_top {
                        display: flex;
                        align-items: center;
                        margin-bottom: 8px;

                        .collect {
                            width: 30px;
                            height: 30px;
                            flex-shrink: 0;
                            box-sizing: border-box;
                            margin-right: 12px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #f5f7fa;
                            border-radius: 4px 4px 4px 4px;
                            border: 1px solid #ffffff;
                            cursor: pointer;
                        }
                        .number {
                            width: 108px;
                            height: 30px;
                            margin-right: 10px;
                            margin-top: -3px;

                            ::v-deep {
                                .el-input__inner {
                                    height: 30px;
                                    line-height: 30px;
                                    background: #f5f7fa;
                                    border: 1px solid #fff;
                                    color: #333;
                                    font-weight: 600;
                                    font-size: 15px;
                                }
                                .el-input-number__decrease,
                                .el-input-number__increase {
                                    width: 22px;
                                    top: 0;
                                    height: 28px;
                                    background: #ecedf0;
                                    border: 1px solid #fff;
                                    color: #333;
                                }
                                .el-icon-plus,
                                el-icon-minus {
                                    font-weight: 600;
                                }
                                .el-input-number__decrease.is-disabled,
                                .el-input-number__increase.is-disabled {
                                    background: #e1e4e9;
                                }
                                .el-input__inner {
                                    padding-left: 22px;
                                    padding-right: 22px;
                                }
                            }
                        }

                        .add {
                            width: 86px;
                            height: 30px;
                            flex-shrink: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: linear-gradient(
                                181deg,
                                #1a9dff 0%,
                                #0687fe 100%
                            );
                            border-radius: 4px 4px 4px 4px;
                            font-size: 14px;
                            color: #ffffff;

                            &:hover {
                                background: linear-gradient(
                                    181deg,
                                    #4daef6 0%,
                                    #0687fe 100%
                                );
                                cursor: pointer;
                                transition: 0.3s;
                            }
                            &.disabled,
                            &.disabled:hover {
                                background: linear-gradient(
                                    181deg,
                                    #86c8f9 0%,
                                    #7cbdf9 100%
                                );
                            }
                            &.exist {
                                background: linear-gradient(
                                    182deg,
                                    #31c19e 0%,
                                    #14ac87 100%
                                );

                                &:hover {
                                    background: linear-gradient(
                                        182deg,
                                        #14ac87 0%,
                                        #31c19e 100%
                                    );
                                    cursor: pointer;
                                    transition: 0.3s;
                                }
                                &.disabled,
                                &.disabled:hover {
                                    background: linear-gradient(
                                        182deg,
                                        #91dac8 0%,
                                        #89d6c3 100%
                                    );
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>